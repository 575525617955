<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row >
        <el-col :span="4">
          <el-select v-model="shopId" placeholder="选择店铺" clearable size="medium">
            <el-option
                v-for="item in shopIdArr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search" size="medium">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-button type="success" @click="dialog.positionAddState = true" style="margin-bottom: 10px">添加位置</el-button>
      <el-table
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :data="table.positionList"
          border
          max-height="700"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column prop="positionName" label="位置名称" align="center" #default="scope">
          {{scope.row.positionName}}
          <el-badge type="primary" value="系统默认" v-if="scope.row.type !== ''"/>
        </el-table-column>
        <el-table-column prop="shopName" label="所在店铺" align="center"/>
        <el-table-column prop="isValid" label="激活状态" align="center">
          <template slot-scope="scope">
            <el-switch
                @change="isValidChange(scope.row)"
                v-model="scope.row.isValid"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="isShare" v-if="isSuperAdmin" label="是否共享" align="center">
          <template slot-scope="scope">
            <el-switch
                @change="isShareChange(scope.row)"
                v-model="scope.row.isShare"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="isDummy" label="虚拟/实际位置" align="center">
          <template slot-scope="scope">
            <el-switch
                @change="isDummyChange(scope.row)"
                v-model="scope.row.isDummy"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column width="350" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="warning" v-show="scope.row.isDefault===0" size="mini" @click="openEdit(scope.row)">编辑</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <positionAdd v-if="dialog.positionAddState"  :state="dialog.positionAddState" @close="close"/>
    <positionEdit v-if="dialog.positionEditState" :position="dialog.position" :state="dialog.positionEditState" @close="close"/>
  </div>
</template>

<script>
import positionAdd from "@/pages/parameter/shop/position-add";
import positionEdit from "@/pages/parameter/shop/position-edit";

export default {
  name: "position-list",
  data() {
    return {
      shopId:"",
      shopIdArr:[],
      tenantCrop: localStorage.getItem("tenantCrop"),
      table: {
        positionList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      dialog: {
        positionAddState: false,
        positionEditState: false,
        position: {},
      },
      isSuperAdmin: localStorage.getItem("tenantCrop") === '2a31c23a-c178-441614928053489'
    }
  },
  components: {
    positionAdd,positionEdit,
  },
  created() {
    this.queryPositionList();
    this.queryShopIdArr();
  },
  methods: {
    search() {
      this.table.page = 1;
      this.queryPositionList();
    },
    //查询客户列表
    queryPositionList: function () {
      this.$axios({
        method: "GET",
        url: "/position/positionList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          shopId: this.shopId,
          tenantCrop: this.tenantCrop,
        }
      }).then(response => {
        this.table.total = response.data.data.total;
        this.table.positionList = response.data.data.list;
      })
    },
    isValidChange:function (value){
      this.$axios({
        method: "PUT",
        url: '/position/updatePosition',
        params: {
          "id": value.id,
          "isValid": value.isValid,
          "tenantCrop":this.tenantCrop
        }
      }).then(response => {
        let isValidState=response.data.code === 200;
        this.$message({
          message: isValidState ? "修改激活状态成功" : response.data.msg,
          type: isValidState ? "success" : 'error'
        });
      })
    },

    isShareChange:function (value){
      this.$axios({
        method: "PUT",
        url: '/position/updatePosition',
        params: {
          "id": value.id,
          "isShare": value.isShare,
          "tenantCrop":this.tenantCrop
        }
      }).then(response => {
        let isShareState=response.data.code === 200;
        this.$message({
          message: isShareState ? "修改共享状态成功" : response.data.msg,
          type: isShareState ? "success" : 'error'
        });
      })
    },
    isDummyChange:function (value){
      this.$axios({
        method: "PUT",
        url: '/position/updatePosition',
        params: {
          "id": value.id,
          "isDummy": value.isShare,
          "tenantCrop":this.tenantCrop
        }
      }).then(response => {
        let isShareState=response.data.code === 200;
        this.$message({
          message: isShareState ? "修改共享状态成功" : response.data.msg,
          type: isShareState ? "success" : 'error'
        });
      })
    },
    //查询店铺
    queryShopIdArr(){
      this.$selectUtils.shopIdsIsValid().then(response=>{
        this.shopIdArr=JSON.parse(response.data.data)
      })
    },
    //打开编辑店铺
    openEdit(data) {
      this.dialog.positionEditState = true
      this.dialog.position = data
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryPositionList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryPositionList()
    },
    close: function (state) {
      this.dialog.positionAddState = state;
      this.dialog.positionEditState = state;
      this.queryPositionList();
    }
  }
}
</script>

<style scoped>

</style>